export function addLinksToText(text) {
  const linkRegex = /(https?:\/\/[^\s]+)/g;
  const linkedText = text.replace(
    linkRegex,
    '<a class="text-brand-link" href="$1">$1</a>'
  );
  return linkedText;
}

export function addEmailLinksToText(text) {
  const emailRegex = /(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b)/g;
  const linkedText = text.replace(
    emailRegex,
    '<a class="text-brand-link" href="mailto:$1?subject=Johns Hopkins Science Calendar Request">$1</a>'
  );
  return linkedText;
}

export function addPhoneLinksToText(text) {
  const phoneRegex = /(\d{3}[-.\s]?\d{3}[-.\s]?\d{4})/g;
  const linkedText = text.replace(
    phoneRegex,
    '<a class="text-brand-link" href="tel:$1">$1</a>'
  );
  return linkedText;
}
