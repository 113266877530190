import Jumbotron from "../Components/Jumbotron";
import Container from "../Components/Container";
import { DataStore } from "@aws-amplify/datastore";
import { Submits } from "../models";
import data from "../Migrate/migrate.json";

export default function Migrate() {
  const isPhoneNumber = (str) => {
    const regex = /^\d{3}-\d{3}-\d{4}$/;
    return regex.test(str);
  };

  const isEmail = (str) => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(str);
  };

  const migrateData = () => {
    const newData = data;
    // console.log(data.length);
    newData.forEach(async (event) => {
      if (event.time) {
        const migratedEvent = {
          title: event.title,
          date: event.date.split(" ")[0],
          time: event.time && event.time.split(" ")[1],
          endTime: event.time && event.time.split(" ")[1],
          eventLocation: event.eventLocation,
          speakerFirstAndLastname: event.speakerFullNameAndDegree.split(",")[0]
            ? event.speakerFullNameAndDegree.split(",")[0]
            : event.speakerFullNameAndDegree,
          speakerDegree: event.speakerFullNameAndDegree.split(",")[1]
            ? event.speakerFullNameAndDegree.split(",")[1].trim()
            : "",
          speakerTitleAndInstitution: event.speakerTitleAndInstitution,
          contactPersonFirstAndLastName: event.contactPersonFirstAndLastName,
          contactPersonEmail: isEmail(event.phoneNumberMoreInfo)
            ? event.phoneNumberMoreInfo
            : "",
          phoneNumberMoreInfo: isPhoneNumber(event.phoneNumberMoreInfo)
            ? event.phoneNumberMoreInfo
            : "",
          adminEmail: event.adminEmail,
          sponsoringDepartmentOrganization:
            event.sponsoringDepartmentOrganization,
          isPublished: event.isPublished === "1" ? true : false,
        };
        console.log(migratedEvent);
        await DataStore.save(new Submits(migratedEvent));
      }
    });
  };

  return (
    <>
      <Jumbotron
        pageTitle="Migrate"
        pageSubTitle="Migrate data from old database"
      />
      <Container>
        <button
          onClick={migrateData}
          className="bg-brand-primary hover:bg-blue-800 text-white py-3 px-7 transition-all mt-4">
          Click the button to migrate the data.
        </button>
      </Container>
    </>
  );
}
