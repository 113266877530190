import { Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "./Components/MainLayout";
import EventsPage from "./Pages/EventsPage";
import EventsNewPage from "./Pages/EventsNewPage";
import EventsUpdatePage from "./Pages/EventsUpdatePage";
import EventsDetailsPage from "./Pages/EventsDetailPage";
import Migrate from "./Pages/Migrate";
import "moment-timezone";

function App() {

  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<EventsPage />} />
        <Route path="/events/:id" element={<EventsDetailsPage />} />
        <Route path="/events/new" element={<EventsNewPage />} />
        <Route path="/events/update/:id" element={<EventsUpdatePage />} />
        <Route path="/migrate" element={<Migrate />} />
        {/* make home page / */}
        {/* if the route does not match redirect to all pages */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </MainLayout>
  );
}

export default App;
