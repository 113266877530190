// export hero banner component

export default function HeroBanner() {
  return (
    <div className="jh-hero-banner-img w-full flex flex-col items-center justify-end min-h-400 relative">
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-0"></div>
      <h1 className="noto-sans text-white z-10 drop-shadow-2xl p-4 flex flex-col md:inline items-center mb-0">
        <span className="block md:inline mr-3">Johns Hopkins</span>
        <span>Science Calendar</span>
      </h1>
      <span className="mb-10 text-white z-0 text-center text-lg md:text-2xl drop-shadow-md px-4">
        A listing of scientific events for the Johns Hopkins Community
      </span>
    </div>
  );
}
