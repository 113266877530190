export function currentEnvironment(baseUrl) {
	
	let isLocal;
	if(
		baseUrl === "http://localhost:3000/" ||
		baseUrl === "http://localhost:3000/#/" ||
		baseUrl === "http://localhost:3000/#/events/new"
	) {
		isLocal = "local";
		return isLocal;
	}
	
	let isDev;
	if(
		baseUrl === "https://scicaldev.hopkinsmedicine.org/" ||
		baseUrl === "https://scicaldev.hopkinsmedicine.org/#/" ||
		baseUrl === "https://scicaldev.hopkinsmedicine.org/#/events/new"
	) {
		isDev = "dev";
		return isDev;
	}
	
}