export default function FormInput({
  label,
  type,
  name,
  onChange,
  value,
  isrequired,
}) {
  return (
    <div className="w-full flex flex-col mb-3">
      <div className="flex items-center mb-1">
        <label>{label}</label>
        {isrequired && <p className="text-red-500 text-sm">*</p>}
      </div>
      <input
        className="border border-gray-800 rounded p-1 w-full md:w-8/12 lg:w-6/12"
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        isrequired="false"
      />
    </div>
  );
}
