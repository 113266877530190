import Quotes from "../StaticData/quotes.json";
import { useEffect, useState } from "react";

export default function QuotesHeader() {
  const [quote, setQuote] = useState(
    Quotes.quotes[Math.floor(Math.random() * 4) + 1]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setQuote(Quotes.quotes[Math.floor(Math.random() * 4) + 1]);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container mx-auto flex justify-center max-w-6xl py-2 px-4 h-80 py-10">
      <blockquote className="max-w-2xl relative">
        <svg
          className="absolute -left-4 -top-4 -z-0"
          clipRule="evenodd"
          fillRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m21.301 4c.411 0 .699.313.699.663 0 .248-.145.515-.497.702-1.788.948-3.858 4.226-3.858 6.248 3.016-.092 4.326 2.582 4.326 4.258 0 2.007-1.738 4.129-4.308 4.129-3.24 0-4.83-2.547-4.83-5.307 0-5.98 6.834-10.693 8.468-10.693zm-10.833 0c.41 0 .699.313.699.663 0 .248-.145.515-.497.702-1.788.948-3.858 4.226-3.858 6.248 3.016-.092 4.326 2.582 4.326 4.258 0 2.007-1.739 4.129-4.308 4.129-3.241 0-4.83-2.547-4.83-5.307 0-5.98 6.833-10.693 8.468-10.693z"
            fillRule="nonzero"
          />
        </svg>
        <p className="text-center mb-6 mt-8">{quote}</p>
      </blockquote>
    </div>
  );
}
