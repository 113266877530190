import { useEffect, useState } from "react";
import FormInput from "../Components/FormInput";
import Button from "../Components/Button";
import { DataStore } from "@aws-amplify/datastore";
import { Submits } from "../models";
import { useNavigate } from "react-router-dom";
import InfoBox from "../Components/InfoBox";

export default function NewEventsForm({ eventToUpdate }) {
  const navigate = useNavigate();
  const [event, setEvent] = useState(eventToUpdate);

  useEffect(() => {
    setEvent(eventToUpdate);
  }, [eventToUpdate]);

  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (
        event.title === "" ||
        event.date === "" ||
        event.time === "" ||
        event.endTime === "" ||
        event.adminEmail === ""
      ) {
        setLoading(false);
        setError("All fields are required.");
        setTimeout(() => setError(""), 3000);
        return;
      }
      if (
        (event.adminEmail.endsWith("@jh.edu") ||
          event.adminEmail.endsWith("@jhmi.edu")) &&
        (event.contactPersonEmail.endsWith("@jh.edu") ||
          event.contactPersonEmail.endsWith("@jhmi.edu"))
      ) {
        await DataStore.save(
          Submits.copyOf(eventToUpdate, (updated) => {
            updated.title = event.title;
            updated.date = event.date;
            updated.time = event.time;
            updated.endTime = event.endTime;
            updated.eventLocation = event.eventLocation;
            updated.speakerDegree = event.speakerDegree;
            updated.speakerTitleAndInstitution =
              event.speakerTitleAndInstitution;
            updated.speakerFirstAndLastname = event.speakerFirstAndLastname;
            updated.contactPersonFirstAndLastName =
              event.contactPersonFirstAndLastName;
            updated.contactPersonEmail = event.contactPersonEmail;
            updated.adminEmail = event.adminEmail;
            updated.sponsoringDepartmentOrganization =
              event.sponsoringDepartmentOrganization;
            updated.phoneNumberMoreInfo = event.phoneNumberMoreInfo;
            updated.isPublished = event.isPublished;
          })
        );
        setLoading(false);
        setSuccess(true);
        setMessage(
          "Event updated successfully. You will be redirected for review or approval."
        );
        setTimeout(() => {
          navigate(`/events/${eventToUpdate.id}`);
        }, 5000);
        window.scrollTo(0, 0);
      } else {
        setError("Please enter a valid JHU email address.");
        setLoading(false);
        setTimeout(() => setError(""), 4000);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (success) {
    return <InfoBox message={message}></InfoBox>;
  }

  return (
    <>
      <form onSubmit={onSubmit} className="py-4">
        <FormInput
          label="Title"
          name="title"
          type="text"
          onChange={onChange}
          value={event.title || ""}
        />
        <FormInput
          label="Date"
          name="date"
          type="date"
          onChange={onChange}
          value={event.date || ""}
        />
        <FormInput
          label="Start Time"
          name="time"
          type="time"
          onChange={onChange}
          value={event.time || ""}
        />
        <FormInput
          label="End Time"
          name="endTime"
          type="time"
          onChange={onChange}
          value={event.endTime || ""}
        />
        <FormInput
          label="Location"
          name="eventLocation"
          type="text"
          onChange={onChange}
          value={event.eventLocation || ""}
        />
        <FormInput
          label="Speaker First and Last Name"
          name="speakerFirstAndLastname"
          type="text"
          onChange={onChange}
          value={event.speakerFirstAndLastname || ""}
        />
        <FormInput
          label="Speaker Degree"
          name="speakerDegree"
          type="text"
          onChange={onChange}
          value={event.speakerDegree || ""}
        />
        <FormInput
          label="Speaker Title and Institution"
          name="speakerTitleAndInstitution"
          type="text"
          onChange={onChange}
          value={event.speakerTitleAndInstitution || ""}
        />
        <FormInput
          label="Contact First and Last Name"
          name="contactPersonFirstAndLastName"
          type="text"
          onChange={onChange}
          value={event.contactPersonFirstAndLastName || ""}
        />
        <FormInput
          label="Contact Email (email to contact about the event)"
          name="contactPersonEmail"
          type="text"
          onChange={onChange}
          value={event.contactPersonEmail || ""}
        />
        <FormInput
          label="Contact Phone Number (number to contact about the event)"
          name="phoneNumberMoreInfo"
          type="tel"
          onChange={onChange}
          value={event.phoneNumberMoreInfo || ""}
        />
        <FormInput
          label="Admin Email (person submitting this form)"
          name="adminEmail"
          type="text"
          onChange={onChange}
          value={event.adminEmail || ""}
        />
        <FormInput
          label="Sponsoring Department or Organization"
          name="sponsoringDepartmentOrganization"
          type="text"
          onChange={onChange}
          value={event.sponsoringDepartmentOrganization || ""}
        />

        {error && <InfoBox isDanger message={error} />}

        <Button
          type="submit"
          label={loading ? "Processing" : "Update"}
          styleClasses="bg-brand-primary hover:bg-blue-800 text-white py-3 px-7 transition-all mt-4"
        />
      </form>
    </>
  );
}
