import { useState } from "react";
import FormInput from "../Components/FormInput";
import Button from "../Components/Button";
import { DataStore } from "@aws-amplify/datastore";
import { Submits } from "../models";
import InfoBox from "../Components/InfoBox";

export default function NewEventsForm() {
  const [event, setEvent] = useState({
    title: "",
    date: "",
    time: "",
    endTime: "",
    speakerDegree: "",
    speakerTitleAndInstitution: "",
    speakerFirstAndLastname: "",
    contactPersonFirstAndLastName: "",
    contactPersonEmail: "",
    adminEmail: "",
    sponsoringDepartmentOrganization: "",
    phoneNumberMoreInfo: "",
    eventLocation: "",
    isPublished: false,
  });

  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (
        event.title === "" ||
        event.date === "" ||
        event.time === "" ||
        event.endTime === "" ||
        event.adminEmail === "" ||
        event.contactPersonEmail === "" ||
        event.eventLocation === ""
      ) {
        setLoading(false);
        setError("Please fill all required fields.");
        setTimeout(() => setError(""), 4000);
        return;
      }

      if (event.time > event.endTime) {
        setLoading(false);
        alert("Verify the event start time is before the event end time.");
        return;
      }
      
      if (
        (event.adminEmail.endsWith("@jh.edu") ||
          event.adminEmail.endsWith("@jhmi.edu") || 
          event.adminEmail.endsWith("@jhu.edu")) &&
        (event.contactPersonEmail.endsWith("@jh.edu") ||
          event.contactPersonEmail.endsWith("@jhmi.edu") || 
          event.contactPersonEmail.endsWith("@jhu.edu"))
      ) {
        const newEvent = await DataStore.save(
          new Submits({
            title: event.title,
            date: event.date,
            time: event.time,
            endTime: event.endTime,
            speakerDegree: event.speakerDegree,
            speakerTitleAndInstitution: event.speakerTitleAndInstitution,
            speakerFirstAndLastname: event.speakerFirstAndLastname,
            contactPersonFirstAndLastName: event.contactPersonFirstAndLastName,
            contactPersonEmail: event.contactPersonEmail,
            adminEmail: event.adminEmail,
            sponsoringDepartmentOrganization:
              event.sponsoringDepartmentOrganization,
            phoneNumberMoreInfo: event.phoneNumberMoreInfo,
            eventLocation: event.eventLocation,
            isPublished: event.isPublished,
          })
        );
        if (newEvent) {
          setSuccess(true);
          setMessage("Event successfully created.");
        }
      } else {
        setError("Please enter a valid JHU email address.");
        setTimeout(() => setError(""), 3000);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <div>
        {message && (
          <>
            <InfoBox message={message}></InfoBox>
            <InfoBox
              isWarning
              message="If you don't see your event in your mail within the next 5 minutes, please check your spam folder."></InfoBox>
            <Button
              type="button"
              onClick={() => {
                window.location.reload();
              }}
              styleClasses="bg-green-700 hover:bg-green-600 text-white p-2 mt-2"
              label="Add Another Event."
            />
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <InfoBox
        isInfo
        message="Upon completion of the form below you will be sent instructions to publish, edit or remove your event. Please fill out the entire form and check for accuracy before submitting."></InfoBox>
      <div>
        <strong className="mr-2">Note:</strong>
        Please note that fields with a red asterisk
        <span className="text-red-600"> *</span> are required.
      </div>
      <form onSubmit={onSubmit} className="py-4">
        <FormInput
          label="Title"
          name="title"
          type="text"
          onChange={onChange}
          value={event.title}
          isrequired="true"
        />
        <FormInput
          label="Date"
          name="date"
          type="date"
          onChange={onChange}
          value={event.date}
          isrequired="true"
        />
        <FormInput
          label="Start Time"
          name="time"
          type="time"
          onChange={onChange}
          value={event.time}
          isrequired="true"
        />
        <FormInput
          label="End Time"
          name="endTime"
          type="time"
          onChange={onChange}
          value={event.endTime}
          isrequired="true"
        />
        <FormInput
          label="Location"
          name="eventLocation"
          type="text"
          onChange={onChange}
          value={event.eventLocation}
          isrequired={true}
        />
        <FormInput
          label="Speaker First and Last Name"
          name="speakerFirstAndLastname"
          type="text"
          onChange={onChange}
          value={event.speakerFirstAndLastname}
        />
        <FormInput
          label="Speaker Degree"
          name="speakerDegree"
          type="text"
          onChange={onChange}
          value={event.speakerDegree}
        />
        <FormInput
          label="Speaker Title and Institution"
          name="speakerTitleAndInstitution"
          type="text"
          onChange={onChange}
          value={event.speakerTitleAndInstitution}
        />
        <FormInput
          label="Contact First and Last Name"
          name="contactPersonFirstAndLastName"
          type="text"
          onChange={onChange}
          value={event.contactPersonFirstAndLastName}
        />
        <FormInput
          label="Contact Email (email to contact about the event)"
          name="contactPersonEmail"
          type="text"
          onChange={onChange}
          value={event.contactPersonEmail}
          isrequired="true"
        />
        <FormInput
          label="Contact Phone Number (number to contact about the event)"
          name="phoneNumberMoreInfo"
          type="tel"
          onChange={onChange}
          value={event.phoneNumberMoreInfo}
        />
        <FormInput
          label="Admin Email (person submitting this form)"
          name="adminEmail"
          type="text"
          onChange={onChange}
          value={event.adminEmail}
          isrequired="true"
        />
        <FormInput
          label="Sponsoring Department or Organization"
          name="sponsoringDepartmentOrganization"
          type="text"
          onChange={onChange}
          value={event.sponsoringDepartmentOrganization}
        />

        {error && <InfoBox isDanger message={error} />}

        <Button
          type="submit"
          // label={loading ? "Processing" : "Submit"}
          label={loading ? "Processing" : "Submit"}
          styleClasses="bg-brand-primary hover:bg-blue-800 text-white py-3 px-7 transition-all mt-4"
        />
      </form>
    </>
  );
}
