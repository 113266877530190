export default function Footer() {
  return (
    <footer>
      <div className="footer-ent">
        <div className="footer-inner">
          <div className="footer-logo">
            <a href="https://hopkinsmedicine.org">
              <span
                className="footer-logo-svg"
                role="img"
                aria-label="Johns Hopkins Medicine footer logo"
              ></span>
              <span className="sr-only">Johns Hopkins Medicine logo</span>
            </a>
          </div>
          <div className="two-column">
            <div className="two-column-left">
              <div className="int-lang">
                <span className="int-lang-label">
                  Language Assistance Available:
                </span>

                <ul className="int-lang-list">
                  <li className="int-lang-item" lang="es">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Spanish"
                    >
                      Espa&ntilde;ol
                    </a>
                  </li>
                  <li className="int-lang-item">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#English"
                    >
                      American Sign Language
                    </a>
                  </li>
                  <li className="int-lang-item">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#hebrew"
                    >
                      עִברִי
                    </a>
                  </li>
                  <li className="int-lang-item">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#yiddish"
                    >
                      אידיש
                    </a>
                  </li>
                  <li className="int-lang-item" lang="am">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Amharic"
                    >
                      አማርኛ
                    </a>
                  </li>
                  <li className="int-lang-item" lang="zh">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Chinese"
                    >
                      繁體中文
                    </a>
                  </li>
                  <li className="int-lang-item" lang="fr">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#French"
                    >
                      Fran&ccedil;ais
                    </a>
                  </li>
                  <li className="int-lang-item" lang="tl">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Tagalog"
                    >
                      Tagalog
                    </a>
                  </li>
                  <li className="int-lang-item fallback" lang="ru">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Russian"
                    >
                      Русский
                    </a>
                  </li>
                  <li className="int-lang-item" lang="pt">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Portuguese"
                    >
                      Portugu&ecirc;s
                    </a>
                  </li>
                  <li className="int-lang-item" lang="it">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Italian"
                    >
                      Italiano
                    </a>
                  </li>
                  <li className="int-lang-item fallback" lang="vi">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Vietnamese"
                    >
                      Tiếng Việt
                    </a>
                  </li>
                  <li className="int-lang-item" lang="kru">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#KruBassa"
                    >
                      Ɓ&agrave;sɔ́ɔ̀-w&ugrave;ɖ&ugrave;-po-nyɔ̀
                    </a>
                  </li>
                  <li className="int-lang-item" lang="ig">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Ibo"
                    >
                      Igbo asusu
                    </a>
                  </li>
                  <li className="int-lang-item" lang="yo">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Yoruba"
                    >
                      &egrave;d&egrave; Yor&ugrave;b&aacute;
                    </a>
                  </li>
                  <li className="int-lang-item" lang="bn">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Bengali"
                    >
                      বাংলা
                    </a>
                  </li>
                  <li className="int-lang-item" lang="ja">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Japanese"
                    >
                      日本語
                    </a>
                  </li>
                  <li className="int-lang-item" lang="ko">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Korean"
                    >
                      한국어
                    </a>
                  </li>
                  <li className="int-lang-item" lang="ht">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Creole"
                    >
                      Krey&ograve;l Ayisyen
                    </a>
                  </li>
                  <li className="int-lang-item" lang="ar">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Arabic"
                    >
                      العربية
                    </a>
                  </li>
                  <li className="int-lang-item" lang="de">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#German"
                    >
                      Deutsch
                    </a>
                  </li>
                  <li className="int-lang-item" lang="pl">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Polish"
                    >
                      Polski
                    </a>
                  </li>
                  <li className="int-lang-item fallback" lang="grk">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Greek"
                    >
                      &Epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;ά
                    </a>
                  </li>
                  <li className="int-lang-item" lang="gu">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Gujarati"
                    >
                      ગુજરાતી
                    </a>
                  </li>
                  <li className="int-lang-item" lang="th">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Thai"
                    >
                      ภาษาไทย
                    </a>
                  </li>
                  <li className="int-lang-item" lang="ur">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Urdu"
                    >
                      اُردُو
                    </a>
                  </li>
                  <li className="int-lang-item" lang="fa">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Persian"
                    >
                      فارسی
                    </a>
                  </li>
                  <li className="int-lang-item fallback" lang="hi">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Hindi"
                    >
                      हिंदी
                    </a>
                  </li>
                  <li className="int-lang-item" lang="pdc">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#PennDutch"
                    >
                      Deitsch
                    </a>
                  </li>
                  <li className="int-lang-item" lang="km">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Cambodian"
                    >
                      ខ្មែរ
                    </a>
                  </li>
                  <li className="int-lang-item">
                    <a
                      className="int-lang-link"
                      href="https://www.hopkinsmedicine.org/patient_care/language-assistance.html#Burmese"
                    >
                      မြန်မာ
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="two-column-right">
              <div className="add-info">
                <div className="add-info-label">
                  Contact &amp; Privacy Information
                </div>

                <div className="info-contact">
                  <a href="https://www.hopkinsmedicine.org/patient_care/patients-visitors/contact-us/">
                    Contact Us
                  </a>
                  <a href="https://www.hopkinsmedicine.org/patient_care/locations/index.html">
                    Patient Care Locations
                  </a>
                </div>

                <div className="info-privacy">
                  <a href="https://www.hopkinsmedicine.org/Privacy/patients.html">
                    Notice of Privacy Practices
                  </a>
                  <a
                    href="https://it.johnshopkins.edu/policies/privacystatement"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Statement
                  </a>
                </div>

                <div className="info-terms">
                  <a href="https://www.hopkinsmedicine.org/Privacy/terms-conditions-of-use.html">
                    Terms &amp; Conditions of Use
                  </a>
                  <a
                    href="https://www.hopkinsmedicine.org/Privacy/_docs/non-discrimination-availability-services-notice.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Non-Discrimination Notice
                  </a>
                </div>
              </div>

              <div className="social-links">
                <ul
                  aria-label="Johns Hopkins Medicine Social Media"
                  className="jssocials-shares clearfix"
                >
                  <li className="jssocials-share" title="Facebook">
                    <a
                      aria-labelledby="link-label-facebook-C9AFE8D2F81B2C238751D6820B63AD43"
                      className="jssocials-share-link facebook"
                      href="https://www.facebook.com/Johns.Hopkins.Medicine"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt=""
                        className="logo"
                        src="https://jhmcdn.blob.core.windows.net/qualtrics-theme/images/facebook.png"
                      />
                      <span
                        className="sr-only"
                        id="link-label-facebook-C9AFE8D2F81B2C238751D6820B63AD43"
                      >
                        Facebook
                      </span>
                    </a>
                  </li>
                  <li className="jssocials-share" title="Twitter">
                    <a
                      aria-labelledby="link-label-twitter-C9AFE8D2F81B2C238751D6820B63AD43"
                      className="jssocials-share-link twitter"
                      href="https://twitter.com/HopkinsMedicine"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt=""
                        className="logo"
                        src="https://jhmcdn.blob.core.windows.net/qualtrics-theme/images/twitter.png"
                      />
                      <span
                        className="sr-only"
                        id="link-label-twitter-C9AFE8D2F81B2C238751D6820B63AD43"
                      >
                        Twitter
                      </span>
                    </a>
                  </li>
                  <li className="jssocials-share" title="LinkedIn">
                    <a
                      aria-labelledby="link-label-linkedin-C9AFE8D2F81B2C238751D6820B63AD43"
                      className="jssocials-share-link linkedin"
                      href="https://www.linkedin.com/company/johns-hopkins-medicine"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt=""
                        className="logo"
                        src="https://jhmcdn.blob.core.windows.net/qualtrics-theme/images/linkedin.png"
                      />
                      <span
                        className="sr-only"
                        id="link-label-linkedin-C9AFE8D2F81B2C238751D6820B63AD43"
                      >
                        LinkedIn
                      </span>
                    </a>
                  </li>
                  <li className="jssocials-share" title="YouTube">
                    <a
                      aria-labelledby="link-label-youtube-C9AFE8D2F81B2C238751D6820B63AD43"
                      className="jssocials-share-link youtube"
                      href="https://www.youtube.com/user/JohnsHopkinsMedicine"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt=""
                        className="logo"
                        src="https://jhmcdn.blob.core.windows.net/qualtrics-theme/images/youtube.png"
                      />
                      <span
                        className="sr-only"
                        id="link-label-youtube-C9AFE8D2F81B2C238751D6820B63AD43"
                      >
                        YouTube
                      </span>
                    </a>
                  </li>
                  <li className="jssocials-share" title="Instagram">
                    <a
                      aria-labelledby="link-label-instagram-C9AFE8D2F81B2C238751D6820B63AD43"
                      className="jssocials-share-link instagram"
                      href="https://www.instagram.com/HopkinsMedicine/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt=""
                        className="logo"
                        src="https://jhmcdn.blob.core.windows.net/qualtrics-theme/images/instagram.png"
                      />
                      <span
                        className="sr-only"
                        id="link-label-instagram-C9AFE8D2F81B2C238751D6820B63AD43"
                      >
                        Instagram
                      </span>
                    </a>
                  </li>
                  <li className="jssocials-share" title="Weibo">
                    <a
                      aria-labelledby="link-label-weibo-C9AFE8D2F81B2C238751D6820B63AD43"
                      className="jssocials-share-link weibo"
                      href="https://www.weibo.com/hopkinsmedicine"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt=""
                        className="logo"
                        src="https://jhmcdn.blob.core.windows.net/qualtrics-theme/images/weibo.png"
                      />
                      <span
                        className="sr-only"
                        id="link-label-weibo-C9AFE8D2F81B2C238751D6820B63AD43"
                      >
                        Weibo
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="footer-inner">
          <div className="copyright">
            Copyright &copy; <span className="currentDate">2023</span> The Johns
            Hopkins University, The Johns Hopkins Hospital, and The Johns
            Hopkins Health System Corporation. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
