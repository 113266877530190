import ScicalItem from "./ScicalItem";
import LimitView from "./LimitView";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import { ReactComponent as SciCalSvg } from "../Svg/scical-blue.svg";
import { ReactComponent as SciCalPlus } from "../Svg/scical-plus.svg";

// pass the events as props from the EventsPage component
export default function ScicalList({
    sciCalEvents,
    pageLimit,
    setPageLimit,
    page,
    setPage,
    eventsCount,
    showPastEvents,
    handleShowPastEvents,
    loading,
}) {
    return (
        <>
            <div className="p-2 flex flex-col items-center md:flex md:flex-row md:justify-between">
                <div className="flex items-center">
                    <SciCalSvg className="w-8 mr-2" />
                    <h2 className="mb-0 mr-2">All Events</h2>
                    <button
                        onClick={handleShowPastEvents}
                        className="mt-2 mr-2 border border-brand-primary px-4">
                        {showPastEvents
                            ? "Show Future Events"
                            : "Show Past Events"}
                    </button>
                </div>
                {eventsCount > 0 && (
                    <div className="flex items-center">
                        <span className="mr-2 mt-2">Showing</span>
                        <LimitView
                            setPageLimit={setPageLimit}
                            eventsShowing={eventsCount}
                        />
                        <span className="ml-2 mt-2">events total.</span>
                    </div>
                )}
            </div>
            <div className="p-2 overflow-x-auto">
                <table
                    id="table"
                    className="min-w-[900px] w-full divide-y divide-gray-300">
                    <thead>
                        <tr className="bg-brand-primary text-white">
                            <th className="border-r">Date & Time</th>
                            <th className="border-r">Details</th>
                            <th className="border-r">Contact Information</th>
                            <th>Download Calendar</th>
                        </tr>
                    </thead>
                    {loading && (
                        <tbody>
                            <tr>
                                <td colSpan="4" className="text-center py-3">
                                    Loading events...
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {sciCalEvents.length === 0 && !loading && (
                        <tbody>
                            <tr>
                                <td colSpan="4" className="text-center py-3">
                                    No events to show, you can always see past
                                    events by clicking the button above (
                                    <strong>Show Past Events</strong>), or
                                    create a new event by clicking the button
                                    below (<strong>New Event</strong>).
                                </td>
                            </tr>
                        </tbody>
                    )}
                    <tbody>
                        {sciCalEvents.map((event) => (
                            <ScicalItem event={event} key={event.id} />
                        ))}
                    </tbody>
                </table>
                <Pagination
                    pageLimit={pageLimit}
                    page={page}
                    setPage={setPage}
                    eventsShowing={sciCalEvents.length}
                />
            </div>
            <div className="w-full flex justify-center pl-2">
                <Link
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    to="/events/new"
                    className="inline-block mt-4 fill-white text-white py-2 px-4 bg-brand-primary">
                    <>
                        <SciCalPlus className="inline mr-2 w-8" />
                        <span className="inline-block mt-1">New Event</span>
                    </>
                </Link>
            </div>
        </>
    );
}
