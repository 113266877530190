// create a skip to main content link that will be on top of the page
import { HashLink as Link } from "react-router-hash-link";

export default function SkipToMain() {
  return (
    <Link to="#main" className="sr-only sr-only-focusable">
      Skip to main content
    </Link>
  );
}
