import Container from "./Container";

export default function Jumbotron({ pageTitle, pageSubTitle }) {
  return (
    <div className="border-b bg-gray-100">
      <Container>
        <h1>{pageTitle}</h1>
        <h2>{pageSubTitle}</h2>
      </Container>
    </div>
  );
}

Jumbotron.defaultProps = {
  pageTitle: "Science Calendar Admin",
  pageSubTitle: "Add, update, and delete events from the Science Calendar",
};
