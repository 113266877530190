import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Container from "../Components/Container";
import Jumbotron from "../Components/Jumbotron";
import Button from "../Components/Button";
import { Link } from "react-router-dom";
import { DataStore } from "aws-amplify";
import { Submits } from "../models";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import InfoBox from "../Components/InfoBox";

export default function EventsDetailsPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [eventDetail, setEventDetail] = useState({});
  const [loading, setLoading] = useState(true);

  const getEventDetail = useCallback(async (id) => {
    setLoading(true);
    const event = await DataStore.query(Submits, id);
    if (event) {
      setEventDetail(event);
      setLoading(false);
      console.log(event);
    }
  }, []);

  useEffect(() => {
    getEventDetail(id);
  }, [id, getEventDetail]);

  // prompt to delete event
  const confirmDeleteEvent = async (id) => {
    const isConfirmed = window.confirm("Delete this event?");
    if (isConfirmed) {
      await DataStore.delete(Submits, id);
      navigate("/");
    }
    return;
  };

  const confirmApproveEvent = async () => {
    if (eventDetail.isPublished === true) {
      alert("This event is already published");
      return;
    }
    const isConfirmed = window.confirm("Approve this event?");
    if (isConfirmed) {
      await DataStore.save(
        Submits.copyOf(eventDetail, (updated) => {
          updated.isPublished = true;
        })
      );
      navigate("/");
    }
    return;
  };

  return (
    <>
      <Jumbotron pageTitle={eventDetail.title} pageSubTitle="Event Details" />
      <Container>
        <InfoBox
          isWarning
          message={`This event is ${
            eventDetail.isPublished
              ? "already published, but you can still make updates or delete it."
              : "not yet approved. Please confirm all of the information is accurate before publishing, press delete if the event was cancelled or press update to make changes."
          }`}
        />
        {
          loading ? (
            <div className="text-center mt-4">
              <span className="text-2xl">Loading...</span>
            </div>
          ) : (
            <>
              <div className="mt-4">
                <div>
                  <span className="text-2xl font-bold text-blue-900">
                    Date, Time & Location
                  </span>
                </div>
                <div className="border border-blue-900 rounded-lg p-2 mt-2">
                  <div>
                    <strong className="mr-2">Date:</strong>
                    <Moment format="MMMM Do, YYYY">
                      {eventDetail.date + "T" + eventDetail.time}
                    </Moment>
                  </div>
                  <div>
                    <strong className="mr-2">Start:</strong>
                    <Moment format="hh:mm A">
                      {eventDetail.date + "T" + eventDetail.time}
                    </Moment>
                  </div>
                  <div>
                    <strong className="mr-2">End:</strong>
                    <Moment format="hh:mm A">
                      {eventDetail.date + "T" + eventDetail.endTime}
                    </Moment>
                  </div>
                  <div>
                    <strong className="mr-2">Location:</strong>
                    {eventDetail.eventLocation}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div>
                  <span className="text-2xl font-bold text-blue-900">
                    Speaker information
                  </span>
                </div>
                <div className="border border-blue-900 rounded-lg p-2 mt-2">
                  <div>
                    <span className="font-bold mr-2">Name:</span>
                    <span>{eventDetail.speakerFirstAndLastname}</span>
                  </div>
                  <div>
                    <span className="font-bold mr-2">Title And Institution:</span>
                    <span>{eventDetail.speakerTitleAndInstitution}</span>
                  </div>
                  <div>
                    <span className="font-bold mr-2">Degree:</span>
                    <span>{eventDetail.speakerDegree}</span>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div>
                  <span className="text-2xl font-bold text-blue-900">Sponsoring</span>
                </div>
                <div className="border border-blue-900 rounded-lg p-2 mt-2">
                  <div>
                    <span className="font-bold mr-2">Department / Organization:</span>
                    <span className="mr-4">
                      {eventDetail.sponsoringDepartmentOrganization}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div>
                  <span className="text-2xl font-bold text-blue-900">
                    For more information
                  </span>
                </div>
                <div className="border border-blue-900 rounded-lg p-2 mt-2">
                  <div>
                    <span className="font-bold mr-2">Name:</span>
                    <span>{eventDetail.contactPersonFirstAndLastName}</span>
                  </div>
                  <div>
                    <span className="font-bold mr-2">Phone:</span>
                    <span>{eventDetail.phoneNumberMoreInfo}</span>
                  </div>
                  <div>
                    <span className="font-bold mr-2">Email:</span>
                    <span>{eventDetail.contactPersonEmail}</span>
                  </div>
                </div>
              </div>
              {/* approve button */}
              <div className="px-1 mt-4">
                {!eventDetail.isPublished && (
                  <Button
                    type="button"
                    styleClasses="bg-green-900 text-white py-3 px-7 transition-all mt-4 mr-2"
                    label="Approve"
                    onClick={() => confirmApproveEvent(eventDetail.id)}
                  />
                )}
                {/* edit link */}
                <Link to={`/events/update/${eventDetail.id}`}>
                  <Button
                    type="button"
                    styleClasses="bg-yellow-700 text-white py-3 px-7 transition-all mt-4 mr-2"
                    label="Update"
                  />
                </Link>
                {/* delete button */}
                <Button
                  type="button"
                  styleClasses="bg-red-900 text-white py-3 px-7 transition-all mt-4 mr-2"
                  label="Delete"
                  onClick={() => confirmDeleteEvent(eventDetail.id)}
                />
              </div>
            </>
          )
        }
      </Container>
    </>
  );
}
