export default function Pagination({
  page,
  setPage,
  pageLimit,
  eventsShowing,
}) {
  const nextPage = (e) => {
    if (e.type === "click" || e.key === "Enter") {
      setPage(page + 1);
    }
    var elem = document.getElementById("table");
    window.scrollTo(0, elem.offsetTop);
  };

  const prevPage = (e) => {
    if (e.type === "click" || e.key === "Enter") {
      setPage(page - 1);
    }
    var elem = document.getElementById("table");
    window.scrollTo(0, elem.offsetTop);
  };

  return (
    <div className="flex items-center justify-between p-2 cursor-pointer text-brand-link font-bold ">
      {page > 0 && (
        <span
          tabIndex={0}
          onClick={prevPage}
          onKeyDown={(e) => prevPage(e)}
          className="underline">
          Previous {pageLimit}
        </span>
      )}

      {eventsShowing < pageLimit - 1 ? null : (
        <span
          tabIndex={0}
          onClick={nextPage}
          onKeyDown={(e) => nextPage(e)}
          className="underline ml-auto">
          Next {pageLimit}
        </span>
      )}
    </div>
  );
}
