export default function InfoBox({
  isWarning = false,
  isInfo = false,
  isDanger = false,
  message,
}) {
  return (
    <div
      className={`${
        isWarning === true
          ? "max-w-3xl p-3 mb-4 bg-yellow-50 border border-yellow-700 text-yellow-700"
          : isInfo === true
          ? "max-w-3xl p-3 mb-4 bg-blue-50 border border-blue-700 text-blue-700"
          : isDanger === true
          ? "max-w-3xl p-3 mb-4 bg-red-50 border border-red-700 text-red-700"
          : "max-w-3xl p-3 mb-4 bg-green-50 border border-green-700 text-green-700"
      }`}
    >
      {message}
    </div>
  );
}
