import { useState } from "react";

export default function LimitView({ setPageLimit, eventsShowing }) {
  const [limitOptions] = useState([10, 20, 30, 40, 50]);

  const onChangeLimit = (e) => {
    setPageLimit(Number(e.target.value));
  };

  return (
    <select
      title="Select view limit"
      className="border border-2 rounded mt-2"
      onChange={onChangeLimit}>
      {limitOptions.map((l) => (
        <option
          className={`${l - 10 > eventsShowing ? "text-red-500" : ""}`}
          key={l}>
          {l}
        </option>
      ))}
    </select>
  );
}
