import { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Quotes from "./Quotes";
import SkipToMain from "./SkipToMain";
import { currentEnvironment } from "../helpers/urlHandler";

export default function MainLayout({ children }) {

  const [location, setLocation] = useState(window.location.href);

  const env = currentEnvironment(location);

  useEffect(() => {
    setLocation(window.location.href);
  }, [location]);

  return (
    <div className="w-full">
      <SkipToMain />
      <Header />
      {
        env === "local" || env === "dev" ? 
        (<p className="p-2 max-w-3xl text-center mx-auto my-2 bg-yellow-100 border border-yellow-500">This is a development environment, please visit <a className="text-brand-link" href="https://scical.hopkinsmedicine.org">https://scical.hopkinsmedicine.org</a> if that is what you are looking for.</p>) : null
      }
      <main id="main" role="main">
        {children}
      </main>
      <Quotes />
      <Footer />
    </div>
  );
}
