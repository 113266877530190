import { AddToCalendarButton } from "add-to-calendar-button-react";
import Moment from "react-moment";
import {
  addLinksToText,
  addEmailLinksToText,
  addPhoneLinksToText,
} from "../helpers/regEx";

export default function ScicalItem({ event }) {
  return (
    <>
      {event.isPublished && (
        <tr className="even:bg-gray-100 mb-0 w-full" key={event.eventId}>
          <td className="px-2 py-5 align-top w-3/12 xl:w-2/12">
            <p>
              <strong>
                <Moment format="MMMM Do, YYYY">{event.date}</Moment>
              </strong>
            </p>
            <p>
              <Moment format="h:mm A">{event.date + "T" + event.time}</Moment>
              <>
                {event.time === event.endTime ? null : (
                  <>
                    {" - "}
                    <Moment format="h:mm A">
                      {event.date + "T" + event.endTime}
                    </Moment>
                  </>
                )}
              </>
            </p>
          </td>
          <td className="px-2 py-5 align-top w-5/12">
            <h5 className="mb-0">
              <strong>{event.title}</strong>
            </h5>
            <p>
              <strong>Speaker: </strong>
              {event.speakerFirstAndLastname}
              {event.speakerDegree && `, ${event.speakerDegree}`}
            </p>
            <div>
              <strong>Location: </strong>
              <span
                dangerouslySetInnerHTML={{
                  __html: addLinksToText(event.eventLocation),
                }}
              />
            </div>
          </td>
          <td className="px-2 py-5 align-top w-2/12">
            {event.contactPersonFirstAndLastName !== "" && (
              <p>
                <strong>{event.contactPersonFirstAndLastName}</strong>
              </p>
            )}
            {event.contactPersonEmail !== "" && (
              <div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: addEmailLinksToText(event.contactPersonEmail),
                  }}
                />
              </div>
            )}
            {event.phoneNumberMoreInfo !== "" && (
              <div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: addPhoneLinksToText(event.phoneNumberMoreInfo),
                  }}
                />
              </div>
            )}
          </td>
          <td className="px-2 py-5 w-2/12 align-middle cursor-pointer">
            <AddToCalendarButton
              name={event.title}
              startDate={event.date}
              options={[
                "Apple",
                "Google",
                "iCal",
                "Microsoft365",
                "Outlook.com",
                "Yahoo",
              ]}
              timeZone="America/New_York"
              iCalFileName={event.title}
              listStyle="modal"
              trigger="click"
              startTime={event.time}
              endTime={event.endTime < event.time ? event.time : event.endTime}
              styleLight="
                --btn-background: transparent;
                --btn-text: #0068c1;
                --btn-border: none;
                --btn-shadow: none;
                --btn-background-hover: transparent;
                --btn-text-hover: rgb(30 58 138);
                --btn-border-hover: none;
                --btn-shadow-hover: none;
                --keyboard-focus: rgb(30 58 138);
                "
            />
          </td>
        </tr>
      )}
    </>
  );
}
