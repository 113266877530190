import { Link, useLocation } from "react-router-dom";

export default function Header({ signOut }) {
  const { pathname } = useLocation();

  const activeLink = (path) => {
    return pathname === path ? "text-brand-primary font-bold" : "text-gray-500";
  };

  return (
    <header className="w-full bg-white text-brand-primary relative h-[30px] md:h-[59px] flex items-center">
      <nav className="enterprise-header-container container h-full mx-auto max-w-6xl flex justify-between px-4">
        <a className="focus-logo" href="https://hopkinsmedicine.org">
          <img
            alt="Johns Hopkins Logo"
            aria-label="Johns Hopkins Logo"
            className="header-logo w-[130px] md:w-[194px] absolute top-0"
            src="https://jhmcdn.blob.core.windows.net/qualtrics-theme/images/jhm-new-header-logo.png"
          />
        </a>
        <ul className="flex flex-row items-center h-full">
          <li
            className={`${activeLink(
              "/"
            )} uppercase text-[12px] md:text-[14px] h-full flex items-center relative mr-2 md:mr-0 px-1 md:px-4`}>
            <Link className="font-bold tracking-wider " to="/">
              Events
            </Link>
          </li>
          <li
            className={`${activeLink(
              "/events/new"
            )} uppercase text-[12px] md:text-[14px] h-full flex items-center relative px-1 md:px-4`}>
            <Link className="font-bold tracking-wider " to="/events/new">
              Submit an event
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
