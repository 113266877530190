import { useEffect, useState, useCallback } from "react";
import WideContainer from "../Components/WideContainer";
import ScicalList from "../Components/ScicalList";
import { DataStore } from "@aws-amplify/datastore";
import { Submits } from "../models";
import HeroBanner from "../Components/HeroBanner";
import { SortDirection } from "@aws-amplify/datastore";

export default function EventsPage() {
    const [sciCalEvents, setSciCalEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [eventsCount, setEventsCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [todayDate, setYesterday] = useState("");
    const [showPastEvents, setShowPastEvents] = useState(false);

    // wrapper function to get all events inside useCallback to avoid re-rendering
    const getAllEvents = useCallback(async () => {
        await DataStore.start();

        if (todayDate) {
            setSciCalEvents(
                await DataStore.query(
                    Submits,
                    (s) =>
                        s.and((s) => [
                            s.isPublished.eq(true),
                            showPastEvents
                                ? s.date.lt(todayDate)
                                : s.date.ge(todayDate),
                        ]),
                    {
                        page: page,
                        limit: pageLimit,
                        sort: (s) => {
                            // return from closest date to furthest date
                            return s.date(
                                showPastEvents
                                    ? SortDirection.DESCENDING
                                    : SortDirection.ASCENDING
                            );
                        },
                    }
                )
            );
            setEventsCount(sciCalEvents.length);
            if (eventsCount === 0) {
                setLoading(false);
            }
        }
    }, [page, pageLimit, showPastEvents, todayDate, sciCalEvents, eventsCount]);

    useEffect(() => {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate());
        yesterday = yesterday.toISOString().split("T")[0];
        setYesterday(yesterday);
        getAllEvents();
    }, [pageLimit, page, getAllEvents]);

    const handleShowPastEvents = () => {
        setShowPastEvents(!showPastEvents);
    };
    return (
        <>
            <HeroBanner />
            <WideContainer>
                <p className="px-2 mb-4 max-w-3xl text-center m-auto">
                    Welcome to Science Calendar Online, a publication of Johns
                    Hopkins Medicine. This calendar lists events sponsored by
                    JHM and other Hopkins-affiliated institutions and is open to
                    anyone in the Hopkins family.
                </p>
                <ScicalList
                    sciCalEvents={sciCalEvents}
                    showPastEvents={showPastEvents}
                    handleShowPastEvents={handleShowPastEvents}
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    page={page}
                    setPage={setPage}
                    eventsCount={eventsCount}
                    loading={loading}
                />
            </WideContainer>
        </>
    );
}
